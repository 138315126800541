<template>
    <div class="modal-suggested-status">
        <div class="content">
            <div class="header flex-row flex-between">
                <div class="title" v-html="$translate(title)" />
                <img class="status-bar" :src="image((options || {}).status)" />
            </div>
            <div class="body" v-html="body" />
        </div>
        <div class="button-wrapper">
            <button @click="$emit('close')" class="btn brand-primary f-16" v-html="$translate('CONFIRM')" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalSuggestedStatus',
    props: ['options'],
    computed: {
        title() {
            return (this.options || {}).title
        },
        body() {
            return (this.options || {}).body
        },
    },
    methods: {
        image(status) {
            return require(`@/assets/images/modal/status-bar-${status}.svg`)
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-suggested-status {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 !important;
    width: 280px;

    .content {
        padding: 20px 24px;

        .header {
            .title {
                line-height: normal;
                color: $purple-primary;
                font-family: NotoSans-Medium;
                font-size: 18px;
                letter-spacing: -0.2px;
            }

            .status-bar {
            }
        }

        .body {
            color: black;
            margin-top: 14px;
            font-size: 15px;
            letter-spacing: -0.2px;
            line-height: normal;
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid $grey-02;

        button {
            font-family: NotoSans-Regular;
        }
    }
}
</style>
